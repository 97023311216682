import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { useUser } from '../../UserContext';

export function useOrgMasqueradeFallback() {
  const user = useUser();
  const { data } = useSWR('org-masquerade-fallback', async () => {
    const orgId = readOrgMasqueradeFallback();
    if (!orgId) return null;
    return apiService.organization.getOrganization(orgId);
  });

  return user.organizer?.organization ?? data?.data.organization;
}

export function writeOrgMasqueradeFallback(orgId: string) {
  sessionStorage.setItem('as-org-id', orgId);
}

function readOrgMasqueradeFallback() {
  return sessionStorage.getItem('as-org-id');
}
